import React, { ReactNode } from 'react'
import { css } from '@emotion/react'

const root = css({
  background: 'white',
  width: '840px',
  margin: '0 auto',
  padding: '4rem 0',
  borderRadius: '2rem',

  '@media(max-width: 896px)': {
    width: '100%',
    borderRadius: '0',
  }
});

type Props = {
  children: ReactNode
}

export default ({ children }: Props) => (
  <div css={root}>
    { children }
  </div>
)