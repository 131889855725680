import React from 'react'
import moment from 'moment'
import { Link } from 'gatsby'
import { css } from '@emotion/react'

const root = css({
  width: '640px',
  margin: '0 auto',
  marginBottom: '4rem',

  '@media(max-width: 896px)': {
    width: 'calc(100% - 20px)',
    maxWidth: '640px',
  }
});

const title = css({
  fontSize: '4rem',
  fontWeight: 'bold',
  lineHeight: '1.2',
});

const date = css({
  fontSize: '1.2rem',
  color: '#999',
  marginTop: '4px',
});

const tagList = css({
  marginTop: '4px',
});

const tagLink = css({
  fontSize: '1.2rem',
  background: '#f0f0f0',
  color: '#999',
  padding: '4px 8px',
  borderRadius: '4px',
  textDecoration: 'none',

  '&:not(:last-child)': {
    marginRight: '8px',
  },

  '&:hover': {
    color: '#666',
  }
});

type Tag = {
  name: string,
  slug: string
}

type Props = {
  post: {
    title: string | null,
    createdAt: string,
    slug: string,
    tags: Tag[] | null
  }
}

export default ({ post }: Props) => {
  const tags = (!post.tags ? [] : post.tags.map((tag) => (
    <Link css={tagLink} key={tag.slug} to={`/tag/${tag.slug}`}>
      <span>{tag.name}</span>
    </Link>
  )));

  const createdAt = moment(post.createdAt).utc().utcOffset('+0900').format('YYYY-MM-DD HH:mm:ss Z');

  return (
    <div css={root}>
      <h1 css={title}>{ post.title || '<Untitled>' }</h1>
      <p css={date}>{ createdAt }</p>
      <div css={tagList}>{ tags }</div>
    </div>
  )
}