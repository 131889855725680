import React from "react"
import { graphql } from "gatsby"

import Layout from '../components/common/Layout';
import Title from '../components/common/Title';

import Post from '../components/post/Post';
import PostHeader from '../components/post/PostHeader';

import 'prismjs/themes/prism-tomorrow.css';
import '../styles/markdown.css';

type Tag = {
  name: string,
  slug: string
}

type Props = {
  data: {
    contentfulBlogPost: {
      body: null | {
        childMarkdownRemark: {
          html: string
        }
      }
      slug: string,
      title: string | null,
      createdAt: string,
      tags: Tag[] | null
    }
  }
}

export default ({ data }: Props) => {
  const post = data.contentfulBlogPost;
  const body = (post.body ? post.body.childMarkdownRemark.html : '');

  return (
    <Layout>
      <Title subTitle={post.title || '<Untitled>'} />
      <Post>
        <PostHeader post={post} />
        <div
          className="markdown-body"
          dangerouslySetInnerHTML={{ __html: body }}
        />
      </Post>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      body {
        childMarkdownRemark {
          html
        }
      }
      slug
      title
      createdAt
      tags {
        name
        slug
      }
    }
  }
`
